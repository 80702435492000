<template>
  <NewProjectsContent />
</template>

<script>
import NewProjectsContent from '@/components/NewProjectsContent.vue'

export default {
  name: 'Projects',
  components: {
    NewProjectsContent,
  },

}
</script>
