<template>
  <div class="container height-full">
    <div class="row height-full">
      <div class="col-12 col-lg-6 height-full">
        <h1>Hey! It's 404</h1>
        <p class="large">This page doesn't exist.</p>
        <router-link class="btn btn-alignment" to="/">Home</router-link>
      </div>
    </div>
  </div>
</template>
