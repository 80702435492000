<template>
  <div>    
    <div class="container height-full">
      <div class="row height-full">
        <div class="col-12 col-lg-6 height-full">
          <h1>Hey!</h1>
          <p class="large">This page is under construction.</p>
          <router-link class="btn btn-alignment" to="/">Home</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.center-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>