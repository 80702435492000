<template>
  <NewHomeContent />
</template>

<script>
import NewHomeContent from '@/components/NewHomeContent.vue'

export default {
  name: 'Home',
  components: {
    NewHomeContent
  }
}
</script>
