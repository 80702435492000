<template>
  <ErrorContent />
</template>

<script>
import ErrorContent from '@/components/ErrorContent.vue'

export default {
  name: 'Home',
  components: {
    ErrorContent
  }
}
</script>
